import styles from './home.module.css';
import { Section } from "../ui/section";

export default function Privacy() {
  return (
    <>
        <Section padding={false}>
                <div className={styles.heading2}>
                    Privacy Policy
                </div>

                
                <h1><b>Privacy Policy for Habbie - Habit tracker &amp; Pet</b></h1>

                <p><b>Effective Date:</b> October 4, 2023</p>

                <p>This Privacy Policy is designed to help you understand how Habbie ("we," "us," or "our") collects, uses, and safeguards your personal information when you use our mobile application, Habbie, as well as any related services (collectively referred to as the "Service").</p>

                <h2><b>Information We Collect</b></h2>

                <p><b>1. Fitness Data:</b> Habbie collects fitness data to track your physical activity and enable you to care for your virtual pet. This data is stored on your device and used solely for the purpose of the app's functionality.</p>

                <p><b>2. Customization Information:</b> If you choose to personalize your virtual pet's appearance and provide a name, this information is stored locally on your device and is not shared with us or any third parties.</p>

                <h2><b>How We Use Your Information</b></h2>

                <p>We use the information we collect for the following purposes:</p>

                <ul>
                    <li>To provide you with the core functionality of Habbie, which involves tracking your fitness to care for your virtual pet.</li>
                    <li>To personalize your virtual pet based on the choices you make within the app.</li>
                    <li>To improve and optimize the performance and user experience of Habbie.</li>
                </ul>

                <h2><b>Data Sharing and Disclosure</b></h2>

                <p>We do not share or disclose your health and/or fitness data or customization information with any third parties, except as required by law or to comply with legal obligations.</p>

                <h2><b>Data Security</b></h2>

                <p>We take reasonable measures to protect your data from unauthorized access or disclosure. However, please be aware that no method of data transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>

                <h2><b>Changes to This Privacy Policy</b></h2>

                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Privacy Policy periodically.</p>


                <p>By using Habbie, you agree to the collection and use of information as described in this Privacy Policy. Your continued use of the Service after any changes to this Privacy Policy will constitute your acceptance of such changes.</p>

        </Section>
    </>
  );
}