import styles from './home.module.css';
import { Section } from "../ui/section";

export default function Terms() {
  return (
    <>
        <Section padding={false}>
            <div className={styles.heading2}>
                Terms of Use
            </div>

            <p><b>Effective Date:</b> October 4, 2023</p>

            <p>Welcome to Habbie! These Terms of Use ("Terms") govern your use of the Habbie mobile application ("App") and the website located at <a href="https://habbie.app">habbie.app</a> ("Website"), which are owned and operated by Habbie ("we," "us," or "our"). By downloading, installing, accessing, or using our App or Website, you ("User" or "you") agree to be bound by these Terms. If you do not agree to these Terms, please do not use the App or Website.</p>

            <p>Please read these Terms carefully before using the App or Website.</p>

            <h2>1. Use of the App</h2>

            <h3>Eligibility</h3>
            <p>To use the App, you must be at least 13 years of age or otherwise capable of forming a legally binding contract under applicable law. If you are under 18, you must have your parent or guardian’s consent to use the App. By using the App, you represent and warrant that you meet these eligibility requirements.</p>

            <h3>License to Use the App</h3>
            <p>Habbie grants you a limited, non-exclusive, non-transferable, revocable license to download, install, and use the App for your personal, non-commercial use, subject to your compliance with these Terms. You may not decompile, reverse-engineer, copy, modify, or distribute the App, except as permitted by law.</p>

            <h2>2. In-App Purchases</h2>

            <p>Habbie offers consumable in-app purchases that can be used within the App ("Consumables"). These Consumables may enhance your experience or provide additional features within the App.</p>

            <h3>Payment</h3>
            <p>All purchases made through the App are processed via Apple's App Store platform. You agree to abide by Apple's applicable payment terms and policies. We do not have access to or store your payment information. All transactions are final, and no refunds will be issued for Consumables, except as required by law or at our sole discretion.</p>

            <h3>Consumables</h3>
            <p>Consumables purchased in the App are for your personal use only and cannot be transferred, resold, or refunded once purchased. Consumables have no monetary value outside of the App and cannot be exchanged for cash or any other goods or services.</p>

            <h3>No Refunds</h3>
            <p>All in-app purchases are final. We do not offer refunds for Consumables, except in cases where required by law. If you encounter issues with your purchase, you may contact Apple Support, as they handle all payment processing and refund requests.</p>

            <h2>3. User Conduct</h2>

            <p>By using the App, you agree not to engage in any of the following prohibited activities:</p>
            <ul>
                <li>Using the App for any unlawful purpose or in violation of any applicable laws or regulations.</li>
                <li>Attempting to interfere with, disrupt, or compromise the security or functionality of the App.</li>
                <li>Impersonating another person or entity or misrepresenting your affiliation with any person or entity.</li>
                <li>Using the App in a way that could harm Habbie or other users, including but not limited to distributing viruses, malware, or other harmful code.</li>
                <li>Harvesting, scraping, or collecting information about other users without their consent.</li>
            </ul>

            <p>We reserve the right to suspend or terminate your access to the App if you violate these Terms or engage in any prohibited conduct.</p>

            <h2>4. Intellectual Property</h2>

            <p>All content, design, graphics, text, and other materials in the App and on the Website are the exclusive property of Habbie or its licensors and are protected by intellectual property laws. You may not copy, reproduce, modify, distribute, or create derivative works based on any content from the App or Website without our prior written consent.</p>

            <h2>5. Third-Party Services</h2>

            <p>The App may contain links to third-party websites or services that are not owned or controlled by Habbie. We are not responsible for the content, privacy practices, or terms of use of third-party websites or services. Your use of any third-party website or service is at your own risk, and you should review the terms and policies of any third-party service you use.</p>

            <h2>6. Disclaimer of Warranties</h2>

            <p>The App and Website are provided on an "as is" and "as available" basis. We do not warrant that the App will be uninterrupted, error-free, or free of viruses or other harmful components. To the fullest extent permitted by law, we disclaim all warranties, whether express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

            <h2>7. Limitation of Liability</h2>

            <p>To the extent permitted by law, in no event will Habbie, its affiliates, or their respective officers, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to lost profits or data, arising out of or related to your use of the App or Website, even if we have been advised of the possibility of such damages.</p>

            <p>Our total liability to you for any claims arising from your use of the App or Website shall not exceed the amount you have paid to us in the past 12 months, if any.</p>

            <h2>8. Indemnification</h2>

            <p>You agree to indemnify, defend, and hold harmless Habbie, its affiliates, and their respective officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of the App, your violation of these Terms, or your violation of any rights of another.</p>

            <h2>9. Changes to Terms</h2>

            <p>We reserve the right to update or modify these Terms at any time. If we make any material changes, we will notify you by posting the updated Terms on our Website or within the App. Your continued use of the App following the posting of any changes constitutes your acceptance of the revised Terms.</p>

            <h2>10. Termination</h2>

            <p>We may terminate or suspend your access to the App at any time, without notice, for conduct that we believe violates these Terms or is harmful to Habbie or other users. Upon termination, the licenses and rights granted to you under these Terms will immediately cease.</p>

            <h2>11. Governing Law</h2>

            <p>These Terms are governed by and construed in accordance with the laws of the jurisdiction where Habbie is based, without regard to conflict of law principles. Any disputes arising out of or relating to these Terms or the use of the App will be subject to the exclusive jurisdiction of the courts in that jurisdiction.</p>

            <h2>12. Contact Us</h2>

            <p>If you have any questions or concerns about these Terms, please contact us at:</p>

            <p><b>Habbie Support</b><br />
            Website: <a href="https://habbie.app">habbie.app</a><br />
            Email: <a href="mailto:support@habbie.app">support@habbie.app</a></p>

            <p>By using the Habbie App or Website, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.</p>
        </Section>
    </>
  );
}
